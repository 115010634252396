<template>
  <main class="d-flex flex-column white fill-height">
    <header class="header px-5 d-flex justify-space-between align-baseline">
      <div class="d-flex justify-space-between align-baseline">
        <div class="title font-weight-regular">
          Customers
        </div>
        <div class="d-flex justify-space-between fill-width">
          <search-component
            v-model="searchOptions"
            :search-fields="$options.availableSearchFields"
          />
        </div>
      </div>
      <div>
        <v-btn
          tile
          outlined
          large
          color="light-blue darken-4"
          class="white--text mx-8"
          min-width="166"
        >
          Report
        </v-btn>
      </div>
    </header>
    <section class="flex-grow-1">
      <data-table
        :headers="$options.customersHeadersConfig"
        :items="customers"
        :options.sync="options"
        :loading="isLoading"
        :items-per-page="100"
        :server-items-length="serverItemsLength"
        :disable-pagination="isLoading"
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-account-outline</v-icon>
            <router-link
              class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
              :to="{ name: 'CustomerDetails', params: { id: item.id } }"
            >
              {{ item.firstName }} {{ item.lastName }}
            </router-link>
          </div>
        </template>
        <template v-slot:item.phone="{ item }">
          <div v-if="item.phone" class="d-flex align-center">
            {{ item.phone }}
          </div>
          <div v-else class="d-flex align-center">
            <span class="blue-grey--text text--lighten-2 font-italic">n/a</span>
          </div>
        </template>
        <template v-slot:item.country="{ item }">
          <div v-if="item.countryOfResidence" class="d-flex align-center">
            {{ item.countryOfResidence }}
          </div>
          <div v-else class="d-flex align-center">
            <span class="blue-grey--text text--lighten-2 font-italic">n/a</span>
          </div>
        </template>
      </data-table>
    </section>
  </main>
</template>

<script>
import { equals } from 'ramda';

import DataTable from '@/components/common/DataTable.vue';
import SearchComponent from '@/components/common/SearchComponent.vue';

import { getCustomersList } from '@/api/customers.api';

import {
  formatDateFieldsInArray,
  transformDataTableOptionsForRequest,
  debounceCall
} from '@/utils';
import CountriesService from '@/services/CountriesService';

import { SORTING_DIRECTIONS } from '@/constants/common';
import { TREATMENT_STATUSES } from '@/constants/treatment';

const debounce500ms = debounceCall(500);

const FIELD_NAMES = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  COUNTRY: 'country',
  STATUS: 'status',
  LAST_ACTIVITY: 'lastActivityDate',
  DOCTOR_NAME: 'doctorName',
  ID: 'id'
};

const FIELD_NAMES_FOR_PARAMS = {
  [FIELD_NAMES.NAME]: 'virtualFullName',
  [FIELD_NAMES.EMAIL]: 'email',
  [FIELD_NAMES.PHONE]: 'phone',
  [FIELD_NAMES.COUNTRY]: 'country',
  [FIELD_NAMES.STATUS]: 'status',
  [FIELD_NAMES.LAST_ACTIVITY]: 'lastActivity',
  [FIELD_NAMES.DOCTOR_NAME]: 'doctorName'
};

const SEARCH_FIELDS = [
  { value: FIELD_NAMES.NAME, text: 'Name' },
  { value: FIELD_NAMES.EMAIL, text: 'Email' },
  { value: FIELD_NAMES.PHONE, text: 'Phone number' },
  { value: FIELD_NAMES.ID, text: 'Id' }
];

const DEFAULT_SORT_BY_FIELD = FIELD_NAMES.LAST_ACTIVITY;

const CUSTOMERS_TABLE_HEADER_CONFIG = [
  { text: 'Name', value: 'name', width: '300px' },
  { text: 'Email', value: 'email', width: '300px' },
  { text: 'Phone', value: 'phone', width: '150px' },
  { text: 'Country', value: 'country', width: '150px' },
  { text: 'Status', value: 'status', width: '200px' },
  { text: 'Last Activity', value: 'lastActivityDate', width: '150px' },
  { text: "Doctor's Name", value: 'doctorName' }
];

export default {
  name: 'CustomersPage',
  components: { DataTable, SearchComponent },
  customersHeadersConfig: CUSTOMERS_TABLE_HEADER_CONFIG,
  availableSearchFields: SEARCH_FIELDS,
  data() {
    return {
      searchOptions: {
        searchString: '',
        searchField: SEARCH_FIELDS[0]
      },
      isLoading: false,
      options: {},
      customers: [],
      serverItemsLength: 0
    };
  },
  watch: {
    async options() {
      this.customers = await this.getCustomers();
    },
    searchOptions: {
      handler: 'onSearchQueryChange',
      deep: true
    }
  },
  methods: {
    onSearchQueryChange(newValue, oldValue) {
      if (!equals(newValue.searchField, oldValue.searchField) && !newValue.searchString) {
        return;
      }

      debounce500ms(this.updateCustomers);
    },
    async updateCustomers() {
      this.customers = await this.getCustomers();
    },
    transformCustomersList(customersList) {
      const listWithFormattedDate = formatDateFieldsInArray(customersList, ['lastActivityDate']);

      return listWithFormattedDate.map(customer => {
        const { countryOfResidence: customerCountry, status: customerStatus } = customer;
        const countryOfResidence = CountriesService.getCountryLabel(customerCountry);
        const status = TREATMENT_STATUSES[customerStatus];

        return { ...customer, status, countryOfResidence };
      });
    },
    async getCustomers() {
      try {
        this.isLoading = true;

        const {
          page,
          sortDirection,
          sortType = DEFAULT_SORT_BY_FIELD,
          size
        } = transformDataTableOptionsForRequest(this.options, SORTING_DIRECTIONS.DESC);

        const requestParams = {
          searchQuery: this.searchOptions.searchString,
          searchColumnName: this.searchOptions.searchField.value,
          sortColumnName: FIELD_NAMES_FOR_PARAMS[sortType],
          sortDirection,
          page,
          size
        };

        const {
          data: { customers, customersTotal }
        } = await getCustomersList(requestParams);

        this.serverItemsLength = customersTotal;

        return this.transformCustomersList(customers);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  min-height: 80px;
}
</style>
